.invoice {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  text-align: left;
  width: 80%;
  max-width: 800px;
  margin: 40px auto;
}
.invoice tr {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0;
}
.invoice tr td {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  vertical-align: top;
  margin: 0;
  padding: 5px 0;
}
.invoice-items {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  margin: 0;
}

br {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0;
}

button {
  background-color: green;
  border: none;
  color: white !important;
  padding: 10px 25px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}
.validateButton {
   
  padding-right: 10px !important; 
   
   
}
body {
  background-color: white !important;
}

#root {
  background-color: #ffffff !important;
  align-items: start;
  justify-content: center;
}

h2,
p {
  font-family: Helvetica;
  color: black;
  text-align: center;
}
label{
  margin-right:14px;
}
.input-field {
  padding: 10px 25px;
   height: 5%;
  margin: 4px 2px; 
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* background: white;
  padding: 50px;
  border-radius: 8px; */
  background: #f5f6fa;;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  padding-top: 0px;
}

.close-button {
  background-color: red;
  border: none;
  color: white !important;
  padding: 10px 25px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}
/*--------- new styles ------ */
.input-content-wrap{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}
.w-30p{
  width: 30% !important;
}
.w-70p{
  width: 80% !important;
}
.w-95{
  width: 95%;
}
.font-600 {
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 0.9rem;
}
.form__input {
 
  border-radius: 0.2rem;
  background: #fff;
  color: #a3a3a3;
  font: inherit;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  outline: 0;
  padding: 12px 14px;
  font-size: 0.9rem;
}
::placeholder{
  font-family: 'Roboto', sans-serif;
}
.btn.green {
  background: #2bdc33;
  /* background: #4CAF50;
  background: #23b32a; */
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}
.btn {
  position: relative;
  display: inline-block;
  margin: 5px 10px;
  padding: 17px 22px;
  width: 120px;
  z-index: 1;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 11px;
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  line-height: normal;
  letter-spacing: 0.05em;
  /* text-transform: uppercase; */
  font-weight: bold;
  border-radius: 0.2rem;
  font-family: 'Roboto', sans-serif;
}
.btn.green span, .btn.green:after {
  background: #23b32a;
  background: #4CAF50;
}
.btn:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 4px;
  z-index: -2;
  content: "";
}
.footer-btn
{
  display: flex;
  justify-content: right;
  margin-top: 0px;
}
.modal-header 
{
  position: relative;
}
.modal-header h5{
  color: #0a4148;
  color: #111111ed;
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Roboto', sans-serif;
}
.modal-header .close{
  position: absolute;
  right: -28px;
  font-size: 1.2rem;
  top: -28px;
  font-weight: 100;
  color: #c3272e;
}
.submit-Input {
  height: 100px;
  padding: 19px;
  font-size: unset;
  width: 100%;
}
.input-Line {
  border-bottom: none;
  padding: 15px;
}
@keyframes spinner-animation { 100% { transform: rotate(360deg) } }